<template>
    <v-progress-circular
        v-if="loading.initial"
        :size="50"
        :color="$const.color.primary"
        indeterminate
        class="d-flex mx-auto"
    ></v-progress-circular>
    <v-form v-else>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-select
            v-model="local.subject"
            :items="subjects"
            :error-messages="getErrors('local.subject')"
            label="Предмет"
            name="subject"
            :color="$const.color.primary"
            @change="calculateName"
        />

        <v-select
            v-model="local.grade"
            :items="grades"
            :disabled="!local.subject"
            :error-messages="getErrors('local.grade')"
            label="Класс"
            name="grade"
            :color="$const.color.primary"
            class="mb-2"
            @change="calculateName"
        />

        <v-label>Содержание</v-label>
        <wysiwyg-editor
            :value="form.content"
            class="mb-5"
            @input="onWysiwygInput"
        />
        <v-alert
          v-if="getErrors('form.content').length"
          dense
          type="error"
        >
            <p v-for="(message, index) in getErrors('form.content')" :key="index" class="mb-1">
                {{ message }}
            </p>
        </v-alert>

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>

import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'
import WysiwygEditor from '@/components/inputs/wysiwyg-editor/index.vue'

export default {
    components: { WysiwygEditor, ActionButtons },
    props: {
        model: { type: Object }
    },
    provide () {
        return {
            model: this.model
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            loading: {
                initial: false
            },
            storeModule: 'hint',
            local: {
                subject: '',
                grade: null
            },
            form: {
                name: '',
                content: '',
                type: 'job-hint'
            }
        };
    },
    computed: {
        subjects () {
            return this.$store.state.app.subjects
        },
        grades () {
            if (!this.local.subject) { return [] }
            return this.subjects.find(item => item.value === this.local.subject)?.grades.flat()
        }
    },
    validations () {
        return {
            local: {
                subject: { required },
                grade: { required }
            },
            form: {
                name: { required },
                content: { required }
            }
        }
    },
    async created () {
        this.loading.initial = true
        try {
            await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        } catch (e) {
            console.error(e)
        } finally {
            this.loading.initial = false
        }
        this.model?.name && this.fillFormFromName(this.model.name)
    },
    methods: {
        fillFormFromName (name) {
            const splittedName = name.split(';')
            const [subject, grade] = [splittedName[0] || null, typeof splittedName[1] === 'string' ? parseInt(splittedName[1]) : null]

            this.local.subject = subject
            this.local.grade = grade

            this.calculateName()
        },
        calculateName () {
            this.form.name = this.local.subject && this.local.grade ? `${this.local.subject};${this.local.grade}` : null
        },
        onValidationFailed () {
            window.scrollTo(0, 0)
        },
        onWysiwygInput (html) {
            if (typeof html === 'string') {
                this.form.content = html
            }
        },
    }
}
</script>